#microsite {
  background-color: #565656 !important;
  color: #fff !important;
}

#microsite,
#feature {
  color: #151515;

  #relatedlinks {
    padding-top: 8px;
  }

  .poll .overx {
    min-height: 178px;
  }

  font-family: Helvetica, Arial, Verdana, 'Hiragino Kaku Gothic Pro',
    'Hiragino Kaku Gothic Pro W3';
  font-size: 16px;

  input,
  select,
  textarea,
  button {
    color: #1a1a1a;
  }

  ol,
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    outline: none;
    -moz-outline-style: none;
  }

  a:active {
    outline: none;
    -moz-outline-style: none;
  }
  a:focus {
    outline: none;
    -moz-outline-style: none;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  img {
    vertical-align: middle;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  input[type='textbox'] {
    width: 100%;
  }

  .clearfix:before,
  .clearfix:after {
    content: ' '; /* 1 */
    display: table; /* 2 */
  }

  .clearfix:after {
    clear: both;
  }

  /*
   * For IE 6/7 only
   * Include this rule to trigger hasLayout and contain floats.
   */

  .clearfix {
    *zoom: 1;
  }

  .disableOnHover:hover {
    color: unset !important;
    background-color: unset !important;
  }

  /*******************
 |STRIP |GRID
********************/
  .strip,
  .grid {
    overflow: hidden;
    white-space: nowrap;
  }

  .strip li,
  .grid li {
    position: relative;
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    padding-right: 8px;
    word-wrap: break-word;
  }

  .grid li {
    float: left;
  }

  .strip.small li,
  .grid.small li,
  .strip li.small,
  .grid li.small {
    width: 152px;
  }

  .strip.standard li,
  .grid.standard li,
  .strip li.standard,
  .grid li.standard {
    width: 312px;
  }

  .strip.large li,
  .grid.large li,
  .strip li.large,
  .grid li.large {
    width: 472px;
  }

  .strip.xlarge li,
  .grid.xlarge li,
  .strip li.xlarge,
  .grid li.xlarge {
    width: 632px;
  }

  .strip.standard-large li {
    width: 312px;
  }

  .strip.large-xlarge li {
    width: 312px;
  }

  @media only screen and (min-width: 980px) and (max-width: 1299px) {
    .strip.standard-large li,
    .strip.large-xlarge li {
      width: 472px;
    }
  }

  @media only screen and (max-width: 663px) {
    .list.minimal-mobile > li:not(:first-child) p,
    .list.minimal-mobile > li:not(:first-child) img {
      display: none;
    }
  }

  .colp5 {
    width: 80px;
  }

  .col1 {
    width: 160px;
  }

  .col1p5 {
    width: 236px;
  }

  .col2,
  .col2-3,
  .col2-4 {
    width: 320px;
  }

  .col2p5 {
    width: 396px;
  }

  .col2-3-4,
  .col3,
  .col3-4,
  .col3-5 {
    width: 480px;
  }

  .col3,
  .col3-4,
  .col3-5 {
    width: 480px;
  }

  .col4,
  .col4-6 {
    width: 640px;
  }

  .col5,
  .col5-7 {
    width: 800px;
  }

  .col6 {
    width: 960px;
  }

  .col1 {
    width: 160px;
  }

  .col1p5 {
    width: 236px;
  }

  /***********************
	|LIST
***********************/
  .list {
  }

  .list.small {
    font-size: 12px;
  }

  .list.large {
    font-size: 16px;
  }

  .list article a > h1 {
    font-size: 16px;
    line-height: 1.15em;
    display: inline;
  }

  .list.small h1 {
    font-size: 12px;
  }

  .list.large h1 {
    font-size: 24px;
  }

  .list p {
    margin: 4px 0;
  }

  .list p.date {
    font-size: 11px;
  }

  .list div.image {
    background-position: center;
    height: 102px;
    margin: 0 0 6px 0;
  }

  .list p.flag {
    font-size: 11px;
  }

  .list p.flag img {
    float: left;
    height: 15px;
    margin: 0 8px 0 0;
    width: 22px;
  }

  .list .date-large {
    font-size: 11px;
  }

  .list .date-large span {
    display: block;
    font-size: 24px;
  }

  .list img {
    margin-bottom: 6px;
    width: 100%;
  }

  /* set font-color by section */
  .list p.copy {
    font-size: 12px;
  }

  .films .list p.copy.large {
    font-size: 16px;
    width: 100%;
  }

  .link-more {
    text-align: center;
    margin: 10px auto 20px auto;
    padding-right: 24px;
  }

  .link-more a {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 2px;
  }

  div.heading-more {
    float: right;
    width: 152px;
    margin-top: -30px;
    height: 24px;
    font-size: 12px;
  }

  .events .list p.copy {
    color: #aaaaaa;
  }

  .list li p.flag,
  .list li p.flag a,
  .events .list p.copy a {
    color: #aaaaaa;
  }

  .list p.copy a:hover,
  .list li p.flag a:hover {
    color: #f2f2f2;
    background-color: #000;
  }

  .list.large p.copy {
    font-size: 16px;
  }

  .list p.user a {
    font-size: 11px;
    color: #ffa200;
  }

  .list p.user a:hover {
    color: #f2f2f2;
    background-color: #000;
  }

  .list a.reference {
    position: absolute;
    right: 8px;
    font-size: 11px;
    color: #b4b4b4;
  }

  /***********************
	|HEADING
***********************/
  h1.heading {
    font-size: 24px;
  }

  h1.heading:not(.listing-heading) {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    padding-bottom: 2px;
    margin: 0 0 4px 0;
  }

  .listing-heading {
    margin: 0 0 0 0;
  }

  h1.heading.small {
    font-size: 16px;
  }

  div.heading-more {
    float: right;
    width: 152px;
    margin-top: -30px;
    height: 24px;
    font-size: 12px;
  }

  @media only screen and (min-width: 664px) {
    h1.listing-heading {
      border-bottom-style: solid;
      border-bottom-width: 2px;
      padding-bottom: 2px;
      margin: 0 0 4px 0;
    }
  }

  @media only screen and (max-width: 979px) {
    h1.heading {
      font-size: 16px;
      /*padding-right: 152px;*/
    }
  }

  /***********************
	|CONTENTLIST
***********************/
  .content-list {
  }

  .content-list > li > ul > li {
    position: relative;
    display: block;
  }

  .content-list > li > ul > li > span {
    position: absolute;
    bottom: 3px;
    left: 10px;
    font-size: 10px;
  }

  .nobreak {
    border-bottom: none !important;
  }

  /***********************
	|EVENTS
***********************/
  .events {
  }

  .events > li {
    background-color: #fff;
    border-bottom: 1px #ccc solid;
  }

  .events > li > span {
    color: #ccc;
  }

  .events .alt {
    background-color: #f0f0f0;
  }

  .events a {
    color: #6b96c2;
  }

  .events a:hover {
    background-color: #6b96c2;
    color: #fff;
  }

  .events h1.heading {
    border-bottom-color: #6b96c2;
    color: #000;
  }

  .events .but {
    background-color: #6b96c2;
  }

  .events .but a {
    color: #fff;
  }

  .events .but:hover {
    background-color: #000;
  }

  /***********************
	|Poll
***********************/
  .poll {
  }

  .poll ul li {
    border-color: #ccc !important;
  }

  .poll.dj ul li {
    border-color: #4b4b4b !important;
  }

  .poll ul li:last-child {
    border-bottom: none;
  }

  @media only screen and (max-width: 663px) {
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
  }

  @media only screen and (min-width: 980px) and (max-width: 1299px) {
    .poll {
      margin-left: -160px;
      width: 952px;
    }
  }

  @media only screen and (min-width: 1300px) {
    .poll {
      margin-left: -160px;
      width: 952px;
    }
  }

  /***********************
	|CountDown
***********************/
  .countDown {
    padding-top: 32px;
  }

  .countDown > li {
    padding: 8px 0 64px 0;
  }

  .countDown > li:not(:first-child) {
    border-top: 1px solid;
  }

  .countDown > li .rank {
    font-size: 48px;
    float: left;
  }

  .countDown > li .dj {
    float: left;
  }

  .countDown > li .copy {
    float: left;
  }

  .countDown.large > li .copy .name {
    font-size: 36px;
  }

  .countDown.large > li .copy .blurb {
    font-size: 12px;
  }

  .countDown.large > li img {
    margin-bottom: 16px;
  }

  .countDown.large > li .nextat {
    margin-top: 16px;
  }

  .first .dj {
    width: 100%;
  }

  .countDown.top > li > div {
    width: 100% !important;
  }

  .countDown.top > li > div.dj {
    margin-bottom: 32px;
  }

  .countDown.top > li div.copy {
    width: initial;
  }

  @media only screen and (max-width: 663px) {
    .countDown > li .rank,
    .countDown > li .dj {
      margin-bottom: 8px;
    }

    .countDown > li .rank {
      width: 100px;
      padding: 10px 0 10px 0;
    }

    .countDown > li > div {
      position: relative;
    }

    .countDown:not(.top) > li .dj {
      padding-top: 46px;
    }

    .poll.labels .countDown:not(.top) > li .dj {
      padding-top: 178px;
    }

    .countDown:not(.top) > li .copy {
      top: 60px;
      position: absolute;
    }

    .countDown:not(.large) > li .copy {
      display: none;
    }

    .countDown.large > li .dj {
      padding-right: 8px;
    }

    .countDown.large > li .dj img {
      width: 100%;
    }

    .countDown.large > li .nextat {
      display: none;
    }
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .countDown:not(.top).large > li .rank {
      width: 100%;
    }

    .countDown.large > li .dj {
      padding-right: 8px;
    }

    .countDown.large > li .dj,
    .countDown.large > li .copy {
      margin: 0;
      width: 49%;
    }
  }

  @media only screen and (min-width: 664px) {
    .countDown > li .rank {
      font-size: 48px;
      float: left;
      padding: 10px 0 10px 0;
    }

    .countDown > li .dj {
      float: left;
    }

    .countDown.large > li .dj img {
      width: 100%;
    }
  }

  @media only screen and (min-width: 980px) {
    .countDown:not(.top) > li {
      width: 952px;
    }

    .countDown:not(.top) > li > div {
      width: 960px;
    }

    .countDown > li .copy {
      float: left;
      padding-top: 10px;
    }
  }

  /***********************
	|MEDIA
***********************/

  /* tablet portrait */
  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .content,
    .scroll,
    footer > ul {
      width: 632px;
    }

    .plus8,
    .grid {
      width: 640px;
    }

    .col4-6 {
      width: 320px;
    }

    .col3-4,
    .col5,
    .col5-7,
    .col6 {
      width: 640px;
    }

    .col2-3-4 {
      width: 320px;
    }
  }

  /* mobile and tablet portrait */
  @media only screen and (max-width: 979px) {
    .list .category {
      display: none;
    }

    .scroll {
      width: 100%;
    }

    .standard .scroll li {
      width: 50%;
    }

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    min-width: 320px;
    width: 100%;

    /* scroll images */
    .review .thumb,
    .news .thumb {
      width: 100%;
    }

    /* buttons */
    .count {
      top: 3px;
      text-indent: 0;
      margin: 0;
    }

    .mobile-only:not(.tablet-off) {
      display: block !important;
    }

    .desktop-wide-off {
      display: none;
    }

    .desktop-off {
      display: none;
    }

    .tablet-off {
      display: none !important;
    }

    .mobile-off {
      display: block;
    }
  }

  /* standard desktop */
  @media only screen and (min-width: 980px) and (max-width: 1299px) {
    .grid:not(.nowidth) {
      width: 960px;
    }

    #logo,
    .content,
    .scroll,
    footer > ul {
      width: 952px;
    }

    .plus8 {
      width: 960px;
    }

    /* scroll images */
    .review .thumb,
    .news .thumb {
      width: 152px;
    }

    .desktop-wide-off {
      display: none;
    }

    .desktop-off {
      display: none;
    }

    .tablet-off {
      display: block;
    }

    .mobile-off {
      display: block;
    }

    .desktop-only {
      display: block !important;
    }
  }

  /* wide desktop */
  @media only screen and (min-width: 1300px) {
    .grid:not(.nowidth) {
      width: 1280px;
    }

    .col2-3 {
      width: 480px;
    }

    .col2-3-4,
    .col2-4,
    .col3-4 {
      width: 640px;
    }

    .col3-5 {
      width: 800px;
    }

    .col4-6 {
      width: 960px;
    }

    .col5-7 {
      width: 1120px;
    }

    #logo,
    .content,
    .scroll,
    footer > ul {
      width: 1272px;
    }

    .home-list #singles.scroll {
      width: auto;
    }

    .plus8 {
      width: 1280px;
    }

    /* scroll images */
    .review .thumb,
    .news .thumb {
      width: 152px;
    }

    .desktop-wide-off {
      display: none;
    }

    .desktop-off {
      display: block;
    }

    .tablet-off {
      display: block;
    }

    .mobile-off {
      display: block;
    }

    .desktop-wide-only {
      display: block !important;
    }

    .strip.large-xlarge li {
      width: 632px;
    }
  }

  .mobile-image {
    display: none;
  }

  /*  mobile */
  @media only screen and (max-width: 663px) {
    .f110 {
      font-size: 64px;
    }

    .mobile-only,
    .mobile-image {
      display: block !important;
    }

    .desktop-wide-off,
    .desktop-off,
    .tablet-off:not(.mobile-only),
    .mobile-off {
      display: none !important;
    }

    .grid:not(.slide),
    .colp5,
    .col1p5,
    .col2,
    .col2p5,
    .col2-3,
    .col2-4,
    .col2-3-4,
    .col3,
    .col3-4,
    .col3-5,
    .col4,
    .col4-6,
    .col5,
    .col5-7,
    .col6 {
      width: 100%;
    }

    .strip,
    .grid {
      white-space: normal;
    }

    .strip.small li,
    .grid.small li,
    .strip li.small,
    .grid li.small,
    .strip.standard li,
    .grid.standard li,
    .strip li.standard,
    .grid li.standard,
    .strip.large li,
    .grid.large li,
    .strip li.large,
    .grid li.large,
    .strip.xlarge li,
    .grid.xlarge li,
    .strip li.xlarge,
    .grid li.xlarge,
    .strip.large-xlarge li {
      float: left;
      margin: 2px 0;
      width: 100%;
    }

    .list {
      padding-bottom: 16px;
    }

    .list li /*:not(:first-child)*/ {
      min-height: 0 !important;
    }

    .list li p.flag,
    .list li p.flag img {
      display: block !important;
    }

    body,
    #header,
    form,
    #footer {
      min-width: 320px;
    }

    /* Content */
    .content {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      padding: 0 8px;
      min-width: 320px;
      width: 100%;
    }

    .content {
      padding: 8px 8px 24px 8px;
    }
  }

  @media only screen and (max-width: 663px) {
    #ulButtons > li {
      margin-bottom: 8px;
    }
  }

  .reading-line-height {
    line-height: 1.5em;
  }

  .pointer {
    cursor: pointer;
  }

  .iOSOnly {
    display: none;
  }

  /***********************
	|Utils
***********************/

  .min-height-medium {
    min-height: 320px;
  }

  .min-height-small {
    min-height: 240px;
  }

  .hidden {
    display: none;
  }

  .invisible {
    visibility: hidden;
  }

  @media only screen and (max-width: 979px) {
    .reverse {
      flex-direction: column-reverse;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }

  @media only screen and (max-width: 664px) {
    .slide.standard.flexible-item > ul > li > article {
      width: 140px;
    }
  }

  /***********************
	FEATURE INTRO
***********************/
  .featureSection.introRelative {
    position: relative;
  }

  .featureSection.introRelative > section {
    max-width: initial;
    position: relative;
    overflow: hidden;
    padding: 0;
  }

  .featureSection.introRelative > section p.intro {
    margin: 40px auto;
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .featureSection.introRelative section {
      padding: 60px 0;
      width: 632px;
    }
  }

  @media only screen and (min-width: 979px) and (max-width: 1299px) {
    .featureSection.introRelative section {
      width: 952px;
    }
  }

  @media only screen and (min-width: 1300px) {
    .featureSection.introRelative section {
      width: 1272px;
    }
  }

  /***********************
	FEATURE NAV
***********************/
  aside.nav {
    display: none;
    background-color: #000000;
    position: fixed;
    width: 50px;
    z-index: 1000;
    height: 100vh;
    top: 0;
    right: 0;

    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    -o-transition: width 0.5s;
    transition: width 0.5s;
  }

  aside.nav.on {
    display: block;
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    -o-transition: width 0.5s;
    transition: width 0.5s;
  }

  aside.nav:hover {
    width: 300px;
  }

  aside.nav ul {
    margin-top: 128px;
  }

  aside.nav ul li {
    height: 64px;
  }

  aside.nav ul li:after {
    background-color: #ffffff;
    display: inline-block;
    content: '';
    width: 32px;
    height: 1px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    position: absolute;
    right: 0;
  }

  aside.nav ul li a {
    display: none;
  }

  aside.nav:hover ul li a {
    display: block;
  }

  /***********************
	FEATURE CONTENTS
***********************/
  .featureSection.headerContents {
    position: relative;
  }

  .featureSection.headerContents.sticky {
    // Removing this on ra-next as causes the image to behave weird...
    // margin-bottom: 350px;
  }

  .featureSection.headerContents > section {
    /*height: 100vh;*/
    max-width: initial;
    position: relative;
    padding: 0;
  }

  .featureSection.headerContents > section > div {
    overflow: hidden;
  }

  .featureSection.headerContents.on > section > div {
    position: fixed;
    width: 100%;
  }

  .featureSection.headerContents.on:not(.bottom) > section > div {
    top: 0;
  }

  .featureSection.headerContents.on.bottom > section > div {
    bottom: 0;
  }

  .featureSection.headerContents.on > section > div > img {
    animation: blur 0.5s forwards;
    -webkit-animation: blur 0.5s forwards;
    -moz-animation: blur 0.5s forwards;
    -o-animation: blur 0.5s forwards;
  }

  .featureSection.headerContents ul {
    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
    display: none;
    font-size: 64px;
    position: absolute;
    bottom: 60px;
    padding-bottom: 16px;
    left: 50%;
  }

  .featureSection.headerContents.on ul {
    display: block;
  }

  .featureSection.headerContents > section > div > ul > li > div > a:hover {
    /*background-color: #7bfffb !important;*/
    color: #151515 !important;
  }

  .featureSection.headerContents a {
    /* remove the ra-next link styling when on top of the image */
    border-bottom: none;
  }

  .featureSection.headerContents ul li {
    line-height: 1;
    opacity: 0;
    min-height: 74px;
    vertical-align: top;
  }

  .featureSection.headerContents ul > li > div:first-child {
    line-height: 16px;
    opacity: 0.4;
    font-size: 16px;
    padding: 8px 16px 0 0;
    vertical-align: top;
    float: left;
  }

  .featureSection.headerContents ul > li > div:last-child {
    margin-left: 36px;
  }

  .featureSection.headerContents ul li > div > div.line {
    background-color: #ffffff;
    display: inline-block;
    width: 96px;
    height: 1px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    vertical-align: -18px;
    margin-left: 16px;
  }

  @keyframes blur {
    from {
      -webkit-filter: blur(0);
      -moz-filter: blur(0);
      -o-filter: blur(0);
      -ms-filter: blur(0);
      filter: blur(0);
    }
    to {
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
      filter: blur(10px);
    }
  }

  @-webkit-keyframes blur {
    from {
      -webkit-filter: blur(0);
      -moz-filter: blur(0);
      -o-filter: blur(0);
      -ms-filter: blur(0);
      filter: blur(0);
    }
    to {
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
      filter: blur(10px);
    }
  }

  @-moz-keyframes blur {
    from {
      -webkit-filter: blur(0);
      -moz-filter: blur(0);
      -o-filter: blur(0);
      -ms-filter: blur(0);
      filter: blur(0);
    }
    to {
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
      filter: blur(10px);
    }
  }

  @-o-keyframes blur {
    from {
      filter: blur(0);
      -webkit-filter: blur(0);
      -moz-filter: blur(0);
      -o-filter: blur(0);
      -ms-filter: blur(0);
    }
    to {
      filter: blur(10px);
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
    }
  }

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-moz-keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-o-keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes moveup {
    from {
      margin-bottom: -20px;
    }
    to {
      margin-bottom: 0;
    }
  }

  @-webkit-keyframes moveup {
    from {
      margin-bottom: -20px;
    }
    to {
      margin-bottom: 0;
    }
  }

  @-moz-keyframes moveup {
    from {
      margin-bottom: -20px;
    }
    to {
      margin-bottom: 0;
    }
  }

  @-o-keyframes moveup {
    from {
      margin-bottom: -20px;
    }
    to {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 663px) {
    .featureSection.headerContents ul {
      bottom: 16px;
      font-size: 36px;
      left: 0;
      margin: 0 8px;
      width: 95%;
    }

    .featureSection.headerContents ul li {
      font-size: 16px;
      margin-bottom: 4px;
      min-height: initial;
      line-height: 16px;
    }

    .featureSection.headerContents ul > li > div:first-child {
      padding-top: 0;
    }

    .featureSection.headerContents ul li > div > div.line {
      display: none;
    }
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .featureSection.headerContents ul {
      margin-left: -325px;
      margin-bottom: 16px;
      width: 632px;
    }

    .featureSection.headerContents ul li {
      font-size: 32px;
      min-height: initial;
    }

    .featureSection.headerContents ul > li > div:first-child {
      padding: 4px 16px 0 0;
    }

    .featureSection.headerContents ul li > div > div.line {
      margin-bottom: 8px;
      width: 50px;
    }
  }

  @media only screen and (max-width: 979px) {
    .featureSection.headerContents > section,
    .featureSection.headerContents > section > div,
    .featureSection.headerContents > section > img {
      width: 100%;
    }

    .featureSection.headerContents > section img:not(.small) {
      display: none;
    }

    .featureSection.headerContents.on ul li {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 980px) {
    .featureSection.headerContents > section img.small {
      display: none;
    }

    .featureSection.headerContents > section {
      width: 100%;
    }

    .featureSection.headerContents > section img {
      width: 100%;
    }

    .featureSection.headerContents.on ul li {
      animation: show 1s forwards;
      -webkit-animation: show 1s forwards;
      -moz-animation: show 1s forwards;
      -o-animation: show 1s forwards;
      animation-delay: 0.7s;
      -webkit-animation-delay: 0.7s;
      -moz-animation-delay: 0.7s;
      -o-animation-delay: 0.7s;
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1299px) {
    .featureSection.headerContents > section {
      width: 100%;
    }

    .featureSection.headerContents ul {
      margin-left: -476px;
      width: 952px;
    }
  }

  @media only screen and (min-width: 1300px) {
    .featureSection.headerContents > section {
      width: 100%;
    }

    .featureSection.headerContents ul {
      margin-left: -637px;
      width: 1272px;
    }
  }

  /***********************
	FEATURE CHAPTER
***********************/
  .featureSection.chapter {
    height: 100vh;
    position: relative;
  }

  .featureSection.chapter.alignTop {
    height: 100vh;
    position: relative;
    min-height: 800px;
    padding-bottom: 160px;
  }
  .featureSection.chapter:not(.alignTop) section {
    max-width: initial;
    position: relative;

    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);

    line-height: 1;
  }

  .featureSection.chapter.alignTop section {
    max-width: initial;
    position: relative;
    padding-top: 80px;

    line-height: 1;
  }

  .featureSection.chapter .sub {
    opacity: 0.95;
  }

  .featureSection.chapter span {
    font-size: 16px;
  }

  .featureSection.chapter h1 {
    font-size: 110px;
    margin: 8px 0 32px 0;
  }

  .featureSection.chapter h2 {
    font-size: 64px;
  }

  .featureSection.chapter div.sub {
    font-size: 24px;
    margin: 32px 0 0 0;
  }

  @media only screen and (max-width: 663px) {
    .featureSection.chapter h2 {
      font-size: 32px;
    }

    .featureSection.chapter div.sub {
      font-size: 32px;
      margin: 0;
    }
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .featureSection.chapter section {
      padding: 120px 0;
      width: 632px;
    }
  }

  @media only screen and (min-width: 664px) {
    .featureSection.chapter {
      min-height: 800px;
    }
  }

  @media only screen and (min-width: 979px) and (max-width: 1299px) {
    .featureSection.chapter section {
      width: 952px;
    }

    .featureSection.chapter section > div {
      width: 50%;
    }

    .featureSection.chapter > section .line {
      background-color: #ffffff;
      width: 0;
      height: 4px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      left: 62%;
      position: absolute;
    }

    .featureSection.chapter > section .line.on {
      animation: showLine 1s forwards;
      -webkit-animation: showLine 1s forwards;
      -moz-animation: showLine 1s forwards;
      -o-animation: showLine 1s forwards;
    }

    @-webkit-keyframes showLine {
      from {
        width: 0;
      }
      to {
        width: 500px;
      }
    }

    @keyframes showLine {
      from {
        width: 0;
      }
      to {
        width: 500px;
      }
    }

    @-moz-keyframes showLine {
      from {
        width: 0;
      }
      to {
        width: 500px;
      }
    }

    @-o-keyframes showLine {
      from {
        width: 0;
      }
      to {
        width: 500px;
      }
    }
  }

  @media only screen and (min-width: 1300px) {
    .featureSection.chapter section {
      width: 1272px;
    }

    .featureSection.chapter section > div {
      width: 50%;
    }

    .featureSection.chapter > section .line {
      background-color: #ffffff;
      width: 0;
      height: 4px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      left: 72%;
      position: absolute;
    }

    .featureSection.chapter > section .line.on {
      animation: showLine 1s forwards;
      -webkit-animation: showLine 1s forwards;
      -moz-animation: showLine 1s forwards;
      -o-animation: showLine 1s forwards;
    }

    @-webkit-keyframes showLine {
      from {
        width: 0;
      }
      to {
        width: 500px;
      }
    }

    @keyframes showLine {
      from {
        width: 0;
      }
      to {
        width: 500px;
      }
    }

    @-moz-keyframes showLine {
      from {
        width: 0;
      }
      to {
        width: 500px;
      }
    }

    @-o-keyframes showLine {
      from {
        width: 0;
      }
      to {
        width: 500px;
      }
    }
  }

  /***********************
	FEATURE STATS
***********************/
  .featureSection.stats {
    position: relative;
    min-height: 100vh;
  }

  .featureSection.stats > section {
    max-width: initial;
    position: relative;
    overflow: hidden;
    padding: 0;
  }

  .featureSection.stats ul {
    position: relative;
  }

  .featureSection.stats ul li {
    display: inline-block;
    font-size: 16px;
    height: 200px;
    margin-bottom: 120px;
    width: 312px;
    padding-right: 8px;
    vertical-align: top;
  }

  .featureSection.stats ul li div {
    line-height: 100%;
  }

  .featureSection.stats ul li div:first-child {
    margin-bottom: 8px;
  }

  .featureSection.stats ul li div:first-child:after {
    background-color: #ffffff;
    content: '';
    display: inline-block;
    width: 28px;
    height: 1px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    vertical-align: -5px;
    margin-left: 8px;
  }

  .featureSection.stats ul li div:not(:last-child) {
    opacity: 0.4;
  }

  .featureSection.stats ul li div:last-child {
    display: none;
  }

  .featureSection.stats ul li .large {
    font-size: 110px;
  }

  .featureSection.stats ul li .small {
    font-size: 24px;
    margin: 16px 16px 0 0;
  }

  @media only screen and (max-width: 663px) {
    .featureSection.stats {
      padding: 0 8px;
      width: initial;
    }

    .featureSection.stats > section {
      height: initial;
      overflow: visible;
      margin: 0 8px;
    }

    .featureSection.stats ul li {
      margin-bottom: 16px;
      width: 100%;
    }
  }

  @media only screen and (min-width: 979px) {
    .featureSection.stats > section {
      height: 100vh;
      min-height: 1200px;
    }

    .featureSection.stats ul {
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .featureSection.stats > section {
      width: 632px;
    }

    .featureSection.stats ul {
      width: 640px;
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1299px) {
    .featureSection.stats > section {
      width: 952px;
    }

    .featureSection.stats ul {
      width: 960px;
    }
  }

  @media only screen and (min-width: 1300px) {
    .featureSection.stats > section {
      width: 1272px;
    }

    .featureSection.stats ul {
      width: 1280px;
    }
  }

  /***********************
	FEATURE PHOTO GALLERY
***********************/
  .featureSection.gallery > section {
    max-width: initial;
    padding: 0;
    position: relative;
    overflow: hidden;
    text-align: center;
  }

  .featureSection.gallery > section > div {
    position: absolute;
    top: 32px;
    font-size: 28px;
    opacity: 0;

    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  .featureSection.gallery > section > div.number {
    left: 32px;
    width: 80px;
    height: 80px;
  }

  .featureSection.gallery > section > div.byline {
    right: 32px;
    height: 200px;
    text-align: left;
    line-height: 1;
  }

  .featureSection.gallery > section:hover > div {
    opacity: 0.6;
  }

  .featureSection.gallery > section > div.number > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
  }

  .featureSection.gallery > section > div > div:last-child {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .featureSection.gallery > section > div > div.line {
    background-color: #ffffff;
    width: 112px;
    height: 1px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    bottom: 0;
    position: absolute;
  }

  @media only screen and (max-width: 979px) {
    .featureSection.gallery > section > div,
    .featureSection.gallery > section > img {
      display: none;
    }

    .featureSection.gallery > section a,
    .featureSection.gallery > section img {
      cursor: default;
      max-width: 632px;
    }

    .featureSection.gallery > section > ul > li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  @media only screen and (min-width: 979px) {
    .featureSection.gallery > section {
      background-color: #000;
    }

    .featureSection.gallery > section > ul {
      position: absolute;
      bottom: -100px;
      opacity: 0;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      width: 100%;
    }

    .featureSection.gallery > section > ul {
      -webkit-transition: bottom 0.5s, opacity 0.5s;
      -moz-transition: bottom 0.5s, opacity 0.5s;
      -o-transition: bottom 0.5s, opacity 0.5s;
      transition: bottom 0.5s, opacity 0.5s;
    }

    .featureSection.gallery > section:hover > ul {
      bottom: 0;
      opacity: 1;
    }

    .featureSection.gallery > section > ul > li {
      background-color: #fff;
      display: inline-block;
      vertical-align: bottom;
    }

    .featureSection.gallery > section > ul > li > a,
    .featureSection.gallery > section > ul > li > a > img {
      height: 76px;
    }

    .featureSection.gallery > section > ul > li > a:hover {
      opacity: 0.5;
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1299px) {
    .featureSection.gallery > section {
      width: 952px;
    }

    .featureSection.gallery > section,
    .featureSection.gallery > section > img {
      height: 595px;
    }
  }

  @media only screen and (min-width: 1300px) and (max-width: 1619px) {
    .featureSection.gallery > section {
      width: 1280px;
    }

    .featureSection.gallery > section,
    .featureSection.gallery > section > img {
      height: 800px;
    }
  }

  @media only screen and (min-width: 1620px) {
    .featureSection.gallery > section {
      width: 1600px;
    }

    .featureSection.gallery > section,
    .featureSection.gallery > section > img {
      height: 1000px;
    }
  }

  /***********************
	FEATURE FULL IMAGE
***********************/
  .featureSection.fullImage > section {
    max-width: initial;
    width: 100%;
    padding: 0;
    position: relative;
    overflow: hidden;
  }

  .featureSection.fullImage img {
    width: 100%;
  }

  .sfeature #feature-content,
  .sfeature header,
  section.largeFeature:empty {
    display: none;
  }

  /***********************
	OTHER
***********************/
  #feature-content.largeFeature {
    margin-bottom: -1px;
  }

  /***********************
	SITE NAV
***********************/

  .white h1 {
    color: #f2f2f2;
  }

  /* black */
  .black #links a,
  .black #links span {
    color: #151515;
  }

  .black h1 {
    color: #151515;
  }

  /***********************
	HEADINGS
***********************/
  h1 {
    font-weight: normal;
  }

  h2 {
    font-weight: normal;
  }

  h3 {
    font-weight: normal;
  }

  h1.special {
    font-size: 1.5em !important;
    color: #151515 !important;
  }

  h1.side {
    font-size: 1.5em;
    line-height: 1.6em;
    border-bottom: 2px solid;
    margin-bottom: 8px;
  }

  .intro {
    font-family: 'AlternateGothicProCyrillic';
    font-size: 50px;
    line-height: 54px;
    max-width: 632px;
    margin: 0 auto 20px;
  }

  /* mobile */
  @media only screen and (max-width: 663px) {
    .intro {
      font-size: 1.2em;
    }

    .year {
      font-size: 1.5em;
    }
  }

  .content-list > li > ul > .events .event img,
  .content-list > .events .event img {
    margin: 6px 0;
  }

  .content-list > li > ul > .events .event a h1,
  .content-list > li > ul > .events a,
  .content-list > .events .event a h1,
  .content-list > .events a {
    color: #6b96c2;
    font-size: 1em;
    line-height: 15px;
  }

  .content-list > li > ul > .events .event a h1:hover,
  .content-list > li > ul > .events a:hover,
  .content-list > .events .event a h1:hover,
  .content-list > .events a:hover {
    background-color: #6b96c2;
    color: #fff;
  }

  .content-list > li > ul > .events .events.images .scroll span,
  .content-list > .events .events.images .scroll span {
    display: block;
    margin: 4px 0;
  }

  .content-list li.music {
    background-color: #1e1e1e;
  }

  .content-list .events #events ul li {
    position: relative;
  }

  .content-list .mobile .events #events ul li {
    float: left;
  }

  .content-list .events #events .date a {
    color: #151515;
    font-size: 1.5em;
  }

  .home-list .left {
    float: left;
    margin-right: 8px;
    width: 312px;
  }

  .home-list .right {
    float: left;
  }

  @media only screen and (min-width: 664px) {
    .home-list #news .popular .list > li a > img {
      height: 206px;
    }

    .home-list #eventreviews .list > li:not(:first-child) a > img,
    .home-list.local #eventreviews .list > li:first-child a > img,
    .home-list #news .strip .list > li a > img {
      height: 100px;
    }

    .home-list #albums .list > li a > img {
      height: 312px;
    }

    .home-list #singles .list > li a > img {
      height: 152px;
    }

    .home-list:not(.local) #eventreviews .list > li:first-child a > img,
    .home-list #tech .list > li a > img {
      height: 206px;
    }

    .home-list #phPhotos .list > li a > img {
      height: 208px;
    }
  }

  .date,
  .date a {
    font-size: 11px;
    color: #b4b4b4;
  }

  #event-listing .date,
  #event-listing .date a {
    font-size: 1em;
    color: #aaaaaa;
  }

  .date a:hover {
    background-color: #000;
    color: #f2f2f2;
  }

  .overx {
    overflow-x: hidden;
  }

  /***********************
	BUTTONS
***********************/
  .but {
    position: relative;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .but:before {
    position: absolute;
    top: 50%;
    content: '';
    background-image: var(--sprite-images);
    background-repeat: no-repeat;
  }

  .but > a,
  .but > input {
    position: relative;
    display: block;
    background-color: transparent !important;
    padding: 6px 0;
    z-index: 2;
    background-image: none !important;
  }

  .but.small {
    font-size: 12px;
    padding: 2px 0;
  }

  .but.large {
    font-size: 24px;
    padding: 2px 0;
    cursor: pointer;
  }

  .but.small {
    font-size: 12px;
  }

  .but.error {
    background-color: #e10 !important;
  }

  .minus:before {
    background-position: 0 -2960px;
    padding: 6px;
    margin-top: -6px;
  }

  .pos {
    position: absolute;
    top: 50%;
    right: 18px;
  }

  .but:hover {
    background-color: #000;
  }

  .but.mobile-grid-width {
    width: 312px;
  }

  /* mobile */
  @media only screen and (max-width: 663px) {
    .but.mobile-grid-width {
      width: 296px;
    }
  }

  /* tablet */
  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .intro {
      font-size: 2em;
    }

    .year {
      font-size: 2em;
    }
  }

  /***********************
	LINKS
***********************/
  a.white:link,
  a.white:visited {
    background-image: linear-gradient(to right, #ff4848, #ff4848);
    background-position: 0 0.97em;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    color: #f2f2f2;
    text-decoration: none;
    font-weight: normal;
  }

  a.white:hover,
  a.grey:hover,
  .list a.reference:hover {
    color: #ff4848 !important;
    text-decoration: none;
    font-weight: normal;
  }

  /* standard disable button across site */
  .but.disabled {
    background-color: #9c9c9c !important;
    cursor: default;
    color: #151515 !important;
  }

  .but.disabled a {
    color: #f2f2f2;
  }

  .but.disabled:hover a {
    color: #f2f2f2;
  }

  /***********************
	Favourites
***********************/
  .dark h1 {
    color: #151515;
  }

  .light h1 {
    color: #f2f2f2;
  }

  /* Styles for fav button view */
  #divFavButton {
    text-align: left;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .plyr {
    filter: alpha(opacity=70);
    opacity: 0.7;
    padding: 0 0 28px 0;
    overflow: hidden;
  }

  @media only screen and (max-width: 663px) {
    .plyr {
      max-height: 134px;
    }

    .plyr iframe {
      height: 100% !important;
      width: 100% !important;
    }

    .advert {
      width: 100%;
    }

    .advert .fl {
      width: 100%;
    }
  }

  /***********************
	COLOURS & SIZES
***********************/
  grey,
  .grey,
  a.grey,
  a.grey {
    color: #aaaaaa !important;
  }

  .black {
    color: #151515 !important;
  }

  .b {
    font-weight: bold;
  }

  .f10 {
    font-size: 12px;
  }

  .f12 {
    font-size: 12px;
  }

  .f16 {
    font-size: 16px;
  }

  .f18 {
    font-size: 18px;
  }

  .f24,
  .f28,
  .h3 {
    font-size: 24px;
  }

  .f36 {
    font-size: 36px;
  }

  .f52 {
    font-size: 52px;
  }

  .f64 {
    font-size: 64px;
  }

  .f110 {
    font-size: 110px;
  }

  .b {
    font-weight: bold;
  }

  .desktop-wide-only {
    display: none;
  }

  .desktop-only {
    display: none;
  }

  .tablet-only {
    display: none;
  }

  .mobile-only {
    display: none !important;
  }

  .p8 {
    padding: 0px 8px 8px 8px;
  }

  .pt80 {
    padding-top: 80px;
  }

  .pt64 {
    padding-top: 64px;
  }

  .pt60 {
    padding-top: 60px;
  }

  .pt49 {
    padding-top: 49px;
  }

  .pt32 {
    padding-top: 32px;
  }

  .pt29 {
    padding-top: 29px;
  }

  .pt24 {
    padding-top: 24px;
  }

  .pt16 {
    padding-top: 16px;
  }

  .pt8 {
    padding-top: 8px;
  }

  .pt4 {
    padding-top: 4px;
  }

  .pt3 {
    padding-top: 3px;
  }

  .pt2 {
    padding-top: 2px;
  }

  .pt1 {
    padding-top: 1px;
  }

  .pl8 {
    padding-left: 8px;
  }

  .pl29 {
    padding-left: 29px;
  }

  .pr3 {
    padding-right: 3px;
  }

  .pr6 {
    padding-right: 6px;
  }

  .pr8 {
    padding-right: 8px;
  }

  .pr16 {
    padding-right: 16px;
  }

  .pb8 {
    padding-bottom: 8px;
  }

  .pb16 {
    padding-bottom: 16px;
  }

  .pb32 {
    padding-bottom: 32px;
  }

  .pb29 {
    padding-bottom: 29px;
  }

  .pb49 {
    padding-bottom: 49px;
  }

  .pb64 {
    padding-bottom: 64px;
  }

  .pb4 {
    padding-bottom: 4px;
  }

  .pb2 {
    padding-bottom: 2px;
  }

  .pb1 {
    padding-bottom: 1px;
  }

  .ptb1 {
    padding: 1px 0px 1px 0px;
  }

  .ptb2 {
    padding: 2px 0px 2px 0px;
  }

  .ptb4 {
    padding: 4px 0px 4px 0px;
  }

  .ptb8 {
    padding: 8px 0px 8px 0px;
  }

  .ptb32 {
    padding: 32px 0;
  }

  .ptb64 {
    padding: 64px 0;
  }

  .m8 {
    margin: 0 8px 8px 8px;
  }

  .mr8b4 {
    margin: 0px 8px 4px 0px;
  }

  .mtb8 {
    margin: 8px 0px 8px 0px;
  }

  .mt8 {
    margin-top: 8px;
  }

  .mt16 {
    margin-top: 16px;
  }

  .mb1 {
    margin-bottom: 1px;
  }

  .mb4 {
    margin-bottom: 4px;
  }

  .mb8 {
    margin-bottom: 8px;
  }

  .mb16 {
    margin-bottom: 16px;
  }

  .mb32 {
    margin-bottom: 32px;
  }

  .mb64 {
    margin-bottom: 64px;
  }

  .mr1 {
    margin-right: 1px;
  }

  .mr4 {
    margin-right: 4px;
  }

  .mr8 {
    margin-right: 8px;
  }

  .ml8 {
    margin-left: 8px;
  }

  .ml-8 {
    margin-left: -8px;
  }

  .m0 {
    margin: 0;
  }

  .cmt16:not(:first-child) {
    margin-top: 16px;
  }

  @media only screen and (max-width: 663px) {
    .mobile-mb64 {
      margin-bottom: 64px;
    }

    .mobile-mb88 {
      margin-bottom: 88px;
    }
  }

  .main-bg {
    background-color: #d1d1d1;
  }

  .white-bg {
    background-color: #ffffff;
  }

  .white-bdr {
    border-color: #ffffff;
  }

  .black-bg {
    background-color: #3f3f3f;
  }

  .dark-bg {
    background-color: #363636;
  }

  .black-bdr {
    border-color: #3f3f3f;
  }

  .greybg {
    background-color: #e3e6e7;
  }

  .grey-bg {
    background-color: #f3f3f3;
  }

  a.null:visited,
  a.null:link,
  .null a:link,
  .null a:visited {
    background-color: transparent;
    text-decoration: none;
    font-weight: normal;
  }

  .no-border {
    border: none !important;
  }

  #track-feed-homepage > li {
    border-bottom: 1px #4b4b4b solid;
    padding-bottom: 5px;
    margin-bottom: 3px;
  }

  .centerImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  body {
    background-color: #595959;
  }

  .alt {
    background-color: #676767;
  }

  main {
    // (ra-next update) commented out as our background is white so we want dark text instead
    // color: #fff;
  }

  main.main-feature {
    color: #151515;
    background-color: #fff;
  }

  a:link {
    background-image: linear-gradient(to right, #ff4848, #ff4848);
    background-position: 0 0.97em;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    color: inherit;
  }

  a:visited {
    background-image: linear-gradient(to right, #ff4848, #ff4848);
    background-position: 0 0.97em;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    color: #ff4848;
    text-decoration: none;
  }

  div.f36 > a:link {
    background-position: 0 1em;

    @-moz-document url-prefix() {
      background-position: 0 0.9em;
    }
  }

  /***********************
	BUTTONS
***********************/
  .but > a {
    color: #f2f2f2;
    border-bottom: none;
  }

  main .but {
    background-color: #ff4848;
  }

  main .but > a {
    color: #f2f2f2;
  }

  main .but > a:hover {
    background-color: transparent;
  }

  main li {
    border-color: #7a7a7a !important;
  }

  /***********************
	FEATURE
***********************/
  #featureHead {
    background-color: #ff4848;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-top: 134px;
  }

  #featureHead h1 {
    line-height: 1.05em;
    /*padding-bottom: 20px;*/
  }

  #featureHead h1.min {
    font-size: 0;
    line-height: 0;
    text-indent: -99999px;
  }

  .large-image {
    background-repeat: no-repeat;
    background-position: top center;
    margin: 0 auto 40px;
  }

  .large-image.youtubeEmbed {
    height: 536px;
  }

  #feature-content > span.f64 {
    line-height: 1.12em;
  }

  #feature-content > span.f36 {
    line-height: 1;
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .large-image.youtubeEmbed {
      height: 356px;
    }
  }

  @media only screen and (max-width: 663px) {
    .large-image.youtubeEmbed {
      height: 176px;
    }

    .month-july {
      margin-top: -54px;
    }
  }

  .standard-image {
    margin: 0 auto 40px;
  }

  .quote {
    font-family: helvetica, 'Lucida Grande', Arial, Verdana;
    font-size: 36px;
    font-weight: bold;
    color: #ff4848;
  }

  .content {
    margin: 0 auto;
    position: relative;
    padding: 20px 0px;
  }

  #feature-content {
    max-width: 632px;
    padding: 16px 8px;
    margin: 0 auto;
  }

  .large-image img {
    max-width: 1340px;
    width: 100%;
    margin: auto;
  }

  @media only screen and (max-width: 979px) {
    .intro {
      font-size: 30px;
      line-height: 30px;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 8px;
    }

    .large-image img,
    .standard-image img {
      width: 100%;
    }

    #pnlSkyscraper {
      display: none;
    }
  }

  /***********************
	MEDIA QUERIES
***********************/
  /* wide desktop */
  @media only screen and (min-width: 1300px) {
    #feature-content,
    .intro,
    .standard-image {
      width: 1280px;
    }

    .large-image {
      width: 100%;
    }
  }

  /* desktop / landscape tablet */
  @media only screen and (min-width: 980px) and (max-width: 1299px) {
    #feature-content,
    .intro,
    .standard-image {
      width: 952px;
    }
  }

  .popularNews {
    margin-bottom: 8px;
  }

  .popularNews li {
    border-bottom: 1px solid #7a7a7a;
    padding: 2px 0;
  }

  #feature-content .film,
  .featureSection .film,
  header .film {
    margin-bottom: 8px;
    height: 536px;
    width: 952px;
  }

  header .film {
    margin: auto;
  }

  @media only screen and (min-width: 980px) and (max-width: 1300px) {
    .featureSection.chapter h2 {
      font-size: 48px !important;
    }

    .mostPopularNews {
      width: 50%;
    }

    .mostPopularAd {
      display: none;
    }
  }

  @media only screen and (min-width: 980px) {
    #feature-content .film,
    .featureSection .film {
      margin-left: -160px;
    }

    #feature-content .film.large,
    .featureSection .film.large {
      height: 715px;
      width: 1272px;
    }
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
    #feature-content .film,
    .featureSection .film,
    header .film {
      height: 356px;
      width: 632px;
    }

    .headlineNews {
      width: 50%;
    }

    .mostPopularNews {
      width: 50%;
    }

    .mostPopularNews > div > div {
      width: 100%;
    }

    .mostPopularAd {
      display: none;
    }
  }

  @media only screen and (max-width: 663px) {
    .mostPopularNews {
      padding-top: 15px;
    }

    .mostPopularAd {
      display: none;
    }

    #feature-content .film,
    .featureSection .film,
    header .film {
      height: 176px;
      width: 312px;
      margin: 0 auto;
    }
  }

  /* month year controller */
  .buttons li {
    margin-bottom: 2px;
    margin-right: 5px;
    /*font-size: 1.4em;*/
    float: left;
    clear: left;
    color: #f2f2f2;
  }

  .buttons li a {
    display: block;
    color: #f2f2f2;
    background-color: #ff4848;
    padding: 10px 14px;
  }

  .buttons li a:hover {
    background-color: #000;
    color: #f2f2f2;
  }

  .buttons .selected {
    display: block;
    color: #151515;
    background-color: #fff;
    padding: 10px 14px;
  }

  .buttons .disabled {
    display: block;
    color: #151515;
    background-color: #444;
    padding: 10px 14px;
  }

  /***********************
	|HEADING
***********************/
  h1.heading {
    border-bottom-color: #ff4848;
    color: #f2f2f2;
  }

  /***********************
	|LIST
***********************/
  .list article > a > h1 {
    color: inherit;
    background-image: linear-gradient(to right, #ff4848, #ff4848);
    background-position: 0 1.04em;
    background-repeat: no-repeat;
    background-size: 100% 1px;
  }

  .list article > a > h1:hover {
    color: #ff4848;
  }

  .list p.copy {
    color: #b4b4b4;
  }

  .list p.copy a {
    color: #b4b4b4;
  }

  /***********************
	|CONTENTLIST
***********************/
  .content-list {
  }

  .content-list > li {
    border-bottom: 1px #7a7a7a solid;
    position: relative;
    display: block;
  }

  .content-list > li > span {
    color: #7a7a7a;
    position: absolute;
    bottom: 3px;
    left: 10px;
    font-size: 10px;
  }

  /***********************
	|EDITORIAL TAGS
***********************/
  .featureSection {
    width: 100%;
    line-height: 1.5em;
  }

  .featureSection > section {
    width: 952px;
    max-width: 632px;
    padding: 16px 8px;
    margin: 0 auto;
  }

  .xi span {
    color: #b4b4b4;
    font-size: 12px;
  }

  @media only screen and (max-width: 979px) {
    .xi {
      margin-bottom: 8px;
    }

    .xi,
    #feature-content > img,
    .featureSection img,
    #feature-content .xi img {
      width: 100%;
    }

    .featureSection > section {
      width: auto;
    }
  }

  @media only screen and (min-width: 980px) {
    .xi.large,
    .xi.xlarge {
      margin-left: -160px;
      margin-bottom: 8px;
    }

    .xi.large,
    .xi.large img,
    .xi.xlarge,
    .xi.xlarge img {
      border: 0;
      width: 952px;
    }

    .xi.left {
      float: left;
      margin: 0 16px 8px -160px;
      width: 472px;
    }

    .xi.left img {
      width: 100%;
    }

    .xi.right {
      float: right;
      margin: 0 -160px 8px 16px;
      width: 472px;
    }

    .xi.right img {
      width: 100%;
    }
  }

  @media only screen and (min-width: 1300px) {
    .xi.xlarge {
      margin-left: -320px;
      margin-bottom: 8px;
    }

    .xi.xlarge,
    .xi.xlarge img {
      border: 0;
      width: 1280px;
    }
  }

  /***********************
	|Review hompage
***********************/

  .reviewRating span,
  .sub {
    color: #b4b4b4;
  }

  @media only screen and (min-width: 664px) {
    .strip.standard .list.reviews article a > h1,
    .strip.standard .list.reviews article .sub {
      font-size: 24px;
    }

    .strip.small .reviewRating {
      font-size: 36px;
      letter-spacing: -1.5px;
      position: absolute;
      bottom: 4px;
    }

    .strip.singleReviews.small .sub {
      padding-bottom: 44px;
    }

    .strip.standard .reviewRating {
      font-size: 64px;
      letter-spacing: -1.5px;
      position: absolute;
      bottom: 4px;
    }

    .reviews li.standard,
    .reviews li.standard h1 {
      font-size: 24px;
    }
  }

  /***********************
	|Poll
***********************/
  .poll ul.albums {
  }

  .poll ul.albums > li {
    padding: 8px 0;
  }

  .poll ul.albums > li {
    border-top: 1px solid #4b4b4b;
  }

  .poll ul.albums > li ul > li {
    float: left;
  }

  .poll ul.albums > li ul > li img {
    width: 100%;
  }

  .poll ul.albums > li ul > li.rank {
    font-size: 48px;
    padding: 10px 0 10px 0;
  }

  .poll ul.albums > li ul > li.rank > div:first-child {
    padding-top: 16px;
  }

  .poll ul.albums > li ul > li:last-child {
    padding-top: 10px;
  }

  .poll ul.albums > li ul > li.col4:last-child {
    padding-top: 4px;
  }

  @media only screen and (max-width: 663px) {
    .poll ul.albums > li ul > li {
      clear: both;
    }
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .poll ul.albums > li {
      padding: 8px 0 32px 0;
    }

    .poll ul.albums > li ul > li.col2 {
      clear: both;
    }

    .poll ul.albums > li:not(:last-child) ul > li:last-child {
      padding-top: 0;
    }

    .poll ul.albums > li ul > li.col3,
    .poll ul.albums > li ul > li.col4 {
      width: 320px;
    }
  }

  @media only screen and (min-width: 980px) {
    .poll ul.albums > li > ul {
      width: 960px;
    }
  }

  /***********************
	|Full image
***********************/
  .fullImage img {
    width: 100%;
  }

  /***********************
	|PHOTO GALLERY
***********************/
  section.gallery {
    width: 100%;
  }

  section.gallery > section {
    max-width: initial;
    margin: 0 auto;
    padding: 0;
    position: relative;
    overflow: hidden;
    text-align: center;
  }

  section.gallery > section > div {
    position: absolute;
    top: 32px;
    font-size: 28px;
    opacity: 0;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  section.gallery > section > div.number {
    left: 32px;
    width: 80px;
    height: 80px;
  }

  section.gallery > section > div.byline {
    right: 32px;
    height: 200px;
    text-align: left;
    line-height: 1;
  }

  section.gallery > section:hover > div {
    opacity: 0.6;
  }

  section.gallery > section > div.number > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
  }

  section.gallery > section > div > div:last-child {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  section.gallery > section > div > div.line {
    background-color: #ffffff;
    width: 112px;
    height: 1px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    bottom: 0;
    position: absolute;
  }

  @media only screen and (max-width: 979px) {
    section.gallery > section > div,
    section.gallery > section > img {
      display: none;
    }

    section.gallery > section a,
    section.gallery > section img {
      cursor: default;
      max-width: 632px;
    }

    section.gallery > section > ul > li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  @media only screen and (min-width: 979px) {
    section.gallery > section {
      background-color: #000;
    }

    section.gallery > section > ul {
      position: absolute;
      bottom: -100px;
      opacity: 0;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      width: 100%;
    }

    section.gallery > section > ul {
      -webkit-transition: bottom 0.5s, opacity 0.5s;
      -moz-transition: bottom 0.5s, opacity 0.5s;
      -o-transition: bottom 0.5s, opacity 0.5s;
      transition: bottom 0.5s, opacity 0.5s;
    }

    section.gallery > section:hover > ul {
      bottom: 0;
      opacity: 1;
    }

    section.gallery > section > ul > li {
      background-color: #fff;
      display: inline-block;
      vertical-align: bottom;
    }

    section.gallery > section > ul > li > a,
    section.gallery > section > ul > li > a > img {
      height: 76px;
    }

    section.gallery > section > ul > li > a:hover {
      opacity: 0.5;
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1299px) {
    section.gallery > section {
      width: 952px;
    }

    section.gallery > section,
    section.gallery > section > img {
      height: 595px;
    }
  }

  @media only screen and (min-width: 1300px) and (max-width: 1619px) {
    section.gallery > section {
      margin: auto;
      width: 1280px;
    }

    section.gallery > section,
    section.gallery > section > img {
      height: 800px;
    }
  }

  @media only screen and (min-width: 1620px) {
    section.gallery > section {
      width: 1600px;
    }

    section.gallery > section,
    section.gallery > section > img {
      height: 1000px;
    }
  }

  /***********************
	|ABOUT TICKETS SECTION
***********************/
  .aboutTickets.features > div {
    float: left;
    padding-top: 16px;
  }

  .aboutTickets.features > div img {
    margin-right: 16px;
    float: left;
  }

  @media only screen and (max-width: 663px) {
    .aboutTickets.features > div {
      clear: both;
    }

    .aboutTickets.features > div > div {
      padding-left: 54px;
    }

    .aboutTickets.features > div img {
      width: 38px;
    }
  }

  @media only screen and (min-width: 664px) {
    .aboutTickets.features > div > div {
      float: left;
    }
  }

  @media only screen and (min-width: 664px) and (max-width: 979px) {
    .aboutTickets.features > div img {
      width: 76px;
    }

    .aboutTickets.features > div {
      min-height: 232px;
      width: 320px;
    }

    .aboutTickets.features > div > div {
      width: 228px;
    }
  }

  @media only screen and (min-width: 980px) {
    .aboutTickets.features > div {
      min-height: 240px;
    }

    .aboutTickets.features > div > div {
      padding-top: 16px;
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1299px) {
    .aboutTickets.features > div {
      width: 480px;
    }

    .aboutTickets.features > div > div {
      width: 312px;
    }
  }

  @media only screen and (min-width: 1300px) {
    .aboutTickets.features > div {
      width: 640px;
    }

    .aboutTickets.features > div > div {
      width: 312px;
    }
  }

  /***************************
	|DESIGN SYSTEM TYPOGRAPHY
  ***************************/
  .light {
    color: #f2f2f2 !important;
  }

  .dark {
    color: #151515 !important;
  }

  .coral {
    color: #ff4848 !important;
  }

  .h1,
  .h2,
  .h3,
  .h4 {
    font-family: 'AlternateGothicProCyrillic';
  }

  .h1.slash,
  .h2.slash,
  .h3.slash,
  .h4.slash {
    color: #ff4848 !important;
    &:before {
      content: '̸';
    }
  }

  // SMALL SCREENS
  .h1 {
    font-size: 50px;
    line-height: 54px;
  }

  .h2 {
    font-size: 36px;
    line-height: 36px;
  }

  .h3 {
    font-size: 30px;
    line-height: 30px;
  }

  .h4 {
    font-size: 24px;
    line-height: 26px;
  }

  // LARGE SCREENS
  @media only screen and (min-width: 1028px) {
    .h1 {
      font-size: 120px;
      line-height: 120px;
    }

    .h2 {
      font-size: 50px;
      line-height: 50px;
    }

    .h3 {
      font-size: 36px;
      line-height: 36px;
    }

    .h4 {
      font-size: 24px;
      line-height: 26px;
    }
  }
}
