@font-face {
  font-family: 'RobotoMono';
  font-display: swap;
  src: local('RobotoMono'),
    url('/static/RobotoMono-Regular.woff2') format('woff2'),
    url('/static/RobotoMono-Regular.woff') format('woff');
}

@font-face {
  font-family: 'AlternateGothicProCyrillic';
  font-display: swap;
  src: local('AlternateGothicProCyrillic'),
    url('/static/AlternateGothicProCyrillic.woff2') format('woff2'),
    url('/static/AlternateGothicProCyrillic.woff') format('woff');
}
